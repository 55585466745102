<template>
  <div>fnvujfbv</div>
</template>

<script setup>
import { useHead } from "@vueuse/head";

// Dynamically set title and meta tags for this component
useHead({
  title: "BIO LINK - All your profile pages in one smart link",
  meta: [
    {
      name: "description",
      content:
        "Our Link in Bio allows you to share all your social media profiles, pages and important links with just one simple url. You can iszzly customize and track link clicks.",
    },
    {
      name: "keywords",
      content:
        "Create bio page, free bio link page, create link in bio, link in bio, customize link in bio for free",
    },
    {
      property: "og:title",
      content: "BIO LINK - All your profile pages in one smart link",
    },
    {
      property: "og:description",
      content:
        "Our Link in Bio allows you to share all your social media profiles, pages and important links with just one simple url. You can iszzly customize and track link clicks.",
    },
  ],
});
</script>

<style lang="scss" scoped></style>
