<template>
  <div>knvjbdv</div>
</template>

<script setup>
import { useHead } from "@vueuse/head";

// Dynamically set title and meta tags for this component
useHead({
  title:
    "QR CODE GENERATOR - Create free qrcode and customize it with advanced analytics.",
  meta: [
    {
      name: "description",
      content:
        "Create free qr code in seconds using Iszify QR Code generator. Customize and share qr code. Get code scans analytics and download qr code in high quality.",
    },
    {
      name: "keywords",
      content:
        "qr code generator, create qr codes, qr code maker, qr generator, qr code creator, qr code, qr code with logo, free qr codes, qr code generator free",
    },
    {
      property: "og:title",
      content:
        "QR CODE GENERATOR - Create free qrcode and customize it with advanced analytics.",
    },
    {
      property: "og:description",
      content:
        "Create free qr code in seconds using Iszify QR Code generator. Customize and share qr code. Get code scans analytics and download qr code in high quality.",
    },
  ],
});
</script>

<style lang="scss" scoped></style>
