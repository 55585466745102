<template>
  <div>jhvufh</div>
</template>

<script setup>
import { useHead } from "@vueuse/head";

// Dynamically set title and meta tags for this component
useHead({
  title:
    "URL SHORTENER - Create short links, customize short links & advanced analytics views",
  meta: [
    {
      name: "description",
      content:
        "Iszzly Create short links with our advanced url shortener tool and share your links in messages, posts and emails. Customize your links and track the number of clicks your link received.",
    },
    {
      name: "keywords",
      content:
        "url shortener, shorten url, short link, shorten link, link shortener, short, qr codes, free, link retargeting, pixel, shortest, URL, tiny, secure, safe, t.ly, short, bit.ly, goo.gl, iszzly, iszify links t.co, link, long url shortened, custom domain",
    },
    {
      property: "og:title",
      content:
        "URL SHORTENER - Create short links, customize short links & advanced analytics views",
    },
    {
      property: "og:description",
      content:
        "Iszzly Create short links with our advanced url shortener tool and share your links in messages, posts and emails. Customize your links and track the number of clicks your link received.",
    },
  ],
});
</script>

<style lang="scss" scoped></style>
